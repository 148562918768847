var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"ean-gravity mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: 'Champs requis',
      regex: 'EAN est invalide',
    },"rules":{
      required: _vm.form.isRequired,
      regex: /^([5414]\d{17})$/,
    },"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('label',{staticClass:"headline col-xxxs-20",attrs:{"for":'ean'}},[_vm._v(" "+_vm._s(_vm.form.label)+" "),(_vm.form.isRequired)?_c('span',[_vm._v("*")]):_vm._e(),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons communeEan",attrs:{"size":"0 0 16 16","symbol":"icons-info"},on:{"click":_vm.showTip}})]),_c('v-input',{staticClass:"col-xxxs-20 col-l-9 error-ean",attrs:{"id":'ean',"placeholder":'5414',"errors":errors,"maxlength":18,"minlength":18,"required":_vm.form.isRequired},on:{"input":validate},model:{value:(_vm.eanNew),callback:function ($$v) {_vm.eanNew=$$v},expression:"eanNew"}})],1)]}}],null,false,3087823814)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }