









































































import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, ref, watch } from '@vue/composition-api'
import VueRecaptcha from 'vue-recaptcha'
import Rgpd from '@/components/Rgpd.vue'

export default defineComponent({
  name: 'captcha-form',
  components: { VueRecaptcha, Rgpd },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const chrome = useState(['chrome'])
    const recaptchaKey = ref(chrome.chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const gdpr = ref('')
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { currentLang } = useGetters(['currentLang'])

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }
    watch(recaptcha, newVal => {
      if (recaptcha.value.length > 0 && gdpr.value) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { catpcha: recaptcha.value, gdpr: gdpr.value },
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })
    watch(gdpr, newVal => {
      if (recaptcha.value.length > 0 && gdpr.value) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { catpcha: recaptcha.value, gdpr: gdpr.value },
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      form: props.content,
      recaptchaKey,
      recaptcha,
      setRecaptcha,
      gdpr,
      resetRecaptcha,
      currentLang,
      ...useState('faq', ['i18n']),
    }
  },
})
