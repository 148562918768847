
















































































import { defineComponent, ref, watch } from '@vue/composition-api'
import InformationBlock from '@/components/connection/new-components/InformationBlock.vue'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'
import { email, regex, required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'

extend('regex', regex)
extend('required', required)
extend('email', email)

export default defineComponent({
  name: 'email-gravity',
  components: { VInput, InformationBlock },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const email = ref('')
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { chrome } = useState(['chrome'])
    const { i18n } = useState('faq', ['i18n'])
    watch(email, newval => {
      if (
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email.value
        )
      ) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: email.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      email,
      form: props.content,
      i18n: chrome.value.data.i18n,
      faqI18n: i18n.value,
      text:
        // eslint-disable-next-line max-len
        'Nous attirons votre attention sur le fait que votre adresse email est recueillie afin de vous informer sur l’évolution de votre demande de raccordement.En communiquant votre adresse Email à RESA vous marquez votre accord explicite sur le fait de recevoir des emails d’informations liés à votre demande et pendant toute la procédure de raccordement.',
    }
  },
})
