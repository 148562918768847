var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-gravity mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: _vm.i18n.gravity.required,
    },"rules":{
      required: _vm.form.isRequired,
    },"name":"text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":errors,"html":_vm.form.description,"label":_vm.form.label,"placeholder":_vm.form.placeholder,"required":_vm.form.isRequired,"type":_vm.form.type},on:{"input":validate},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }