















































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'
import { regex, required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'

extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'phone-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const phone = ref('')
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { chrome } = useState(['chrome'])
    watch(phone, newval => {
      if (
        /^(1[0-69]|[23][2-8]|4[23]|4[0-9]\d|5\d|6[01345789]|7[01689]|8[0-79]|9[012])\d{6}$/.test(
          phone.value
        )
      ) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: phone.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      phone,
      form: props.content,
      i18n: chrome.value.data.i18n,
    }
  },
})
