
















































































import { defineComponent, ref, watch } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'name-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { chrome } = useState(['chrome'])
    const lastname = ref('')
    const firstname = ref('')

    watch(lastname, newVal => {
      if (lastname.value.length > 0 && firstname.value.length > 0) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { lastname: lastname.value, firstname: firstname.value },
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    watch(firstname, newVal => {
      if (lastname.value.length > 0 && firstname.value.length > 0) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { lastname: lastname.value, firstname: firstname.value },
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      firstname,
      lastname,
      form: props.content,
      i18n: chrome.value.data.i18n,
    }
  },
})
