




































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'information-block',
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
