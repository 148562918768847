





























































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import { required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

import TipPanel from '@/components/TipPanel.vue'

extend('required', required)

export default defineComponent({
  name: 'ean-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { chrome } = useState(['chrome'])
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    const ean = ref('')

    const eanNew = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          ean.value,
          '##################'
        )
      },
      set(val: string) {
        ean.value = val.replace(/^0|\//, '')
      },
    })
    const showTip = e => {
      e.preventDefault()
      Axios.get(`${getApiUrl()}/faq/preparez-vous/`).then(({ data }) => {
        showPanel({
          component: { template: TipPanel },
          content: data.content,
        })
      })
    }

    watch(eanNew, newval => {
      if (eanNew.value.length === 18) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: eanNew.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      ean,
      showTip,
      eanNew,
      form: props.content,
      i18n: chrome.value.data.i18n,
    }
  },
})
