










































































































































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'address-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { i18n } = useState('faq', ['i18n'])
    // const { address } = check
    const gravStreet = ref()
    const gravNumber = ref()
    const gravCommune = ref()
    const gravZip = ref()

    onBeforeMount(() => {
      for (const item of props.content.inputs) {
        if (!item.isHidden && item.label === 'Street Address') {
          gravStreet.value = item
        }
        if (!item.isHidden && item.label === 'State / Province') {
          gravCommune.value = item
        }
        if (!item.isHidden && item.label === 'ZIP / Postal Code') {
          gravZip.value = item
        }
        if (!item.isHidden && item.label === 'Address Line 2') {
          gravNumber.value = item
        }
      }
    })

    const street = ref('')
    const number = ref('')
    const zip = ref('')
    const commune = ref('')

    watch(street, newVal => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: {
          street: street.value,
          number: number.value,
          commune: commune.value,
          zip: zip.value,
        },
      })
    })

    watch(number, newVal => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: {
          street: street.value,
          number: number.value,
          commune: commune.value,
          zip: zip.value,
        },
      })
    })
    watch(commune, newVal => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: {
          street: street.value,
          number: number.value,
          commune: commune.value,
          zip: zip.value,
        },
      })
    })
    watch(zip, newVal => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: {
          street: street.value,
          number: number.value,
          commune: commune.value,
          zip: zip.value,
        },
      })
    })
    // const requiredFields: CheckAddressRequiredFields = {
    //   zip: false,
    //   city: false,
    //   street: false,
    //   streetNumber: false,
    // }

    return {
      street,
      number,
      zip,
      commune,
      form: props.content,
      gravStreet,
      gravNumber,
      gravCommune,
      gravZip,
      i18n,
      // requiredFields,
    }
  },
})
